<template>
  <div>
    <div class="row">
      <div class="col-md-8 offset-md-2">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12 text-center">
                <h4 class="text-success">Income</h4>
                <h2 class="text-success"><i class="fa fa-dollar-sign"/><span
                    v-if="account">{{ total_asset | to2DecimalPlace }}</span><span v-else>Loading...</span></h2>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-6 border-right">
                <h5 class="text-muted">Total Profit</h5>
                <h4><i class="fa fa-dollar-sign"/><span v-if="account">{{ 0 | to2DecimalPlace }}</span></h4>
              </div>
              <div class="col-6">
                <h5 class="text-muted">Deposited Amount</h5>
                <h4><i class="fa fa-dollar-sign"/>3000</h4>
              </div>
            </div>

          </div>
        </div>
      </div>
      <!-- end col -->
    </div>


    <div class="row">
      <div class="col-md-8 offset-md-2">
        <b-overlay :show="loader" rounded="sm">

          <template #overlay>
            <div>
              <b-spinner small type="grow" variant="danger"></b-spinner>
              <b-spinner type="grow" variant="danger"></b-spinner>
              <b-spinner small type="grow" variant="danger"></b-spinner>
              <span class="sr-only text-dark">Please wait...</span>
            </div>
          </template>

          <div class="card">
            <div class="card-body">
              <h4 class="header-title mb-3">List of Agreement</h4>
              <div class="table-responsive mb-0">
                <table class="table table-borderless table-hover table-centered m-0">
                  <thead class="thead-light">
                  <tr>
                    <th>Time</th>
                    <th>Income</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(data,i) in revenueData" :key="i">
                    <td>
                      <h5 class="m-0 font-weight-normal">{{ data.date }}</h5>
                    </td>
                    <td>{{ data.income }}</td>

                  </tr>
                  </tbody>
                </table>
              </div>
              <!-- end .table-responsive-->
            </div>
          </div>


        </b-overlay>

      </div>
    </div>
    <!-- end row -->
    <mobilefooter/>

  </div>
</template>

<script>
import WidgetChart from "/src/views/pages/dashboard/analytics/components/Widget-chart";
import RevenueReport from "/src/views/pages/dashboard/analytics/components/Revenue-report";
import ProductsSales from "/src/views/pages/dashboard/analytics/components/Products-sales";
import MarketingReports from "/src/views/pages/dashboard/analytics/components/Marketing-reports";
import Portlet from "/src/views/pages/dashboard/analytics/components/Portlet";
import RevenueHistory from "/src/views/pages/dashboard/analytics/components/Revenue-history";
import Projections from "/src/views/pages/dashboard/analytics/components/Projections";


export default {
  name: "index",
  components: {
    mobilefooter: () => import("@/components/MobileFooter")

  },
  data() {
    return {
      tableTitle: "Top Selling Products",
      revenueData: [
        {
          date: "1 Week",
          income: "10%",
        },
        {
          date: "2 weeks",
          income: "30%",
        },
        {
          date: "1 month",
          income: "45%",
        },
      ],
      loader: false,
      account: ''
    }
  },
  methods: {
    getUserAccountInfo() {
      this.accountLoader = true;
      this.$store.dispatch('getUserAccountDetails').then((res) => {
        const {status, extra} = res;
        if (status) {
          this.account = extra;
          this.total_asset = Number(this.account.investors_account.wallet_total_balance) + Number(this.account.investors_account.referral_total_balance)
        }
      }).finally(() => {
        this.accountLoader = false;
      })
    }

  },
  created() {
    this.getUserAccountInfo();
  }

}
</script>

<style scoped>

</style>